<template>
    <div class="access-aksk">
        <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
            <OptButton size="small" type="primary"  class="_create" @click="showCreate">创建访问秘钥</OptButton>
            <OptButton size="small" icon="icon iconfont iconbianzu1711"
                       style="margin-left:8px;color:#949494;transform: translateY(2px);min-width: 32px;width: 32px!important;padding: 6px 7px!important;"
                       @click="getAksk"></OptButton>
        </div>

        <el-table :data="tableData" v-loading="loadingData" style="width: 100%">
            <!--        <el-table-column type="selection" width="60"></el-table-column>-->
            <el-table-column prop="accessKey" label="Access Key" ></el-table-column>
            <el-table-column prop="regionName" label="资源节点" ></el-table-column>
            <el-table-column prop="create" label="创建时间" >
                <template slot-scope="{$index, row}">
                    {{ moment(row.create).format('YYYY-MM-DD HH:mm:ss') }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="80">
                <template slot-scope="{ $index, row }">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <el-button size="small" :disabled="isDisabled(row)" type="text" class="table-action-button" @click="handleDelete(row)">删除</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :title="title" :visible.sync="visible" append-to-body
                   :close-on-click-modal="false" width="484px">
            <el-form :model="form" ref="form" @submit.native.prevent
                     label-width="100px" style="">
                <el-form-item label="资源节点">
                    <el-select
                        v-model="form.regionId"
                        placeholder="选择资源池"
                        disabled
                        style="margin-right: 10px;"
                    >
                        <el-option
                            v-for="item in regionList"
                            :value="item.regionId"
                            :label="item.regionName"
                            :key="item.regionId"/>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCancel" >取消</el-button>
                <el-button size="small" type="primary" :loading="loading" @click="handleSumit">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import storage from "@/utils/storage";
export default {
    name: "access-aksk",
    props: ['accountId', 'username'],
    data () {
        return {
            tableData: [],
            loadingData: false,
            loading: false,
            pager: {
                current: 1,
                size: 10,
                total: 0
            },
            title: '创建访问秘钥',
            form: {
                regionId: ''
            },
            regionList: [],
            visible: false
        }
    },
    created() {
        this.getRegionList()
        this.getAksk()

    },
    methods: {
        moment,
        getRegionList () {
            this.$post('/region/listPurchased', {})
                .then(res => {
                    if (res.code === '200') {
                        this.regionList = [].concat(res.data || []);
                    }
                })
        },
        getAksk () {
            const  {accountId} = this;
            this.loadingData = true
            this.$postJson('/auth/user/aksk/list', {accountId})
            .then(res => {
                this.tableData = res.data
            })
            .finally(() =>{
                this.loadingData = false
            })
        },
        showCreate () {
            this.visible = true
            this.form.regionId = storage.get('REGION_ID')

        },
        handleDelete ({regionId, id}) {
            const {accountId} = this;
            this.$confirm('确定删除吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$postJson('/auth/user/aksk/delete', {regionId, accountId, akskId: id})
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('操作成功')
                        this.getAksk();
                        this.$emit('reload')
                    }
                })
            })
        },
        handleCancel () {
            this.visible = false
        },
        handleSumit () {
            const {form: {regionId}, accountId, tableData} = this;
            if (tableData.filter(i => i.regionId === regionId).length > 1) {
                this.$message.warning('每个资源池最多可创建两对AKSK')
                return
            }
            this.loading = true
            this.$postJson('/auth/user/aksk/create', {regionId, accountId})
            .then(res => {
                if (res.code === '200') {
                    this.$message.success('操作成功')
                    this.visible = false
                    this.getAksk()
                    this.$emit('reload')
                }
            })
            .finally(() => {
                this.loading = false
            })
        },
        isDisabled ({regionId}) {
            const {tableData} = this
            return tableData.filter(i => i.regionId === regionId).length < 2
        }
    }
}
</script>

<style scoped>

</style>