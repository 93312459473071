<template>
    <div class="access-control-manager">
        <div class="header">
            <el-breadcrumb class="crumb" separator="/">
                <span @click="goBack" class="access-back"><i class="el-icon-arrow-left"/></span>
                <el-breadcrumb-item :to="{ path: '/accessControl/list' }" class="bucket-crumb">访问控制</el-breadcrumb-item>
                <el-breadcrumb-item class="bucket-crumb">{{ detail.username }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="account-detail">
            <el-col :span="8">
                <div class="info-item">
                    <div class="info-label" style="">用户账号</div>
                    <div class="info-value ellipsis el-tooltip">{{ detail.username }}</div>
                </div>
                <div class="info-item">
                    <div class="info-label" style="">状态</div>
                    <div class="info-value">{{ {NORMAL: '启用', DISABLE: '停用'}[detail.status] }}
                        <span v-if="detail.username" class="edit-btn" @click="handleEditUser">编辑</span>
                    </div>

                </div>
            </el-col>
            <el-col :span="8">
                <div class="info-item">
                    <div class="info-label">
                        手机号码
                    </div>
                    <div class="info-value">{{ detail.phone }}<span v-if="detail.username" class="edit-btn" @click="handleEditUser">编辑</span></div>
                </div>
                <div class="info-item">
                    <div class="info-label">邮箱地址</div>
                    <div class="info-value">{{ detail.mail }} <span v-if="detail.username" class="edit-btn" @click="handleEditUser">编辑</span></div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="info-item">
                    <div class="info-label">创建时间</div>
                    <div class="info-value">{{ moment(detail.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</div>
                </div>
                <div class="info-item">
                    <div class="info-label">可访问桶数量</div>
                    <div class="info-value">{{ detail.bucketCount }}</div>
                </div>
            </el-col>
        </div>
        </div>
        <div class="content">
            <el-tabs v-model="activeName" @tab-click="handleTabChange">
                <el-tab-pane name="1" >
                    <span slot="label">权限设置</span>
                    <setAccess v-if="accountId" @reload="getAccountDetail(accountId)" ref="serAccessRef" :accountId="accountId" :username="detail.username"/>
                </el-tab-pane>
                <el-tab-pane name="2" >
                    <span  slot="label">访问秘钥</span>
                    <AKSK  v-if="accountId" ref="akskRef" @reload="getAccountDetail(accountId)" :accountId="accountId" :username="detail.username"/>
                </el-tab-pane>
            </el-tabs>
            <CreateUser ref="createUserRef" @reload-list="getAccountDetail"/>
        </div>
    </div>
</template>

<script>
import setAccess from "@/views/accessControl/component/setAccess";
import AKSK from './component/aksk'
import CreateUser from "@/views/accessControl/component/CreateUser";
import moment from 'moment'
export default {
    name: "access-control-manager",
    components: {setAccess, AKSK, CreateUser},
    data() {
        return {
            activeName: '1',
            detail: {},
            accountId: ''
        }
    },

    created() {
        const {accountId} = this.$route.query
        this.accountId = accountId
        if (!accountId) {
            this.$message.warning('无法获取账号信息')
        } else {
            this.getAccountDetail(accountId)
        }
    },
    methods: {
        moment,
        goBack() {
            this.$router.go(-1)
        },
        getAccountDetail (accountId) {
            accountId = accountId || this.accountId
            this.$get('/accountUser/getAccountUser?accountUserId='+accountId)
            .then(res => {
                if (res.code === '200') {
                    this.detail = res.data
                }
            })
        },
        handleTabChange (val) {
        },
        handleEditUser () {
            this.$refs.createUserRef.show(this.detail)
        }
    }
}
</script>

<style scoped lang="scss">
.access-control-manager {
    height: 100%;
}
.content {
    min-height: calc(100% - 236px);
    margin: 0 18px 0;
    background: #fff;
    padding: 0 20px;
}
.access-back {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    margin-left: 10px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    color: #555555;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
}
.header {
    margin: 10px 18px;
    height: 174px;
}
.account-detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
        width: 33%;
    }
}
.account-detail {
    padding: 20px 0;
    background: white;
    .info-item {
        display: flex;
        padding-left: 18px;
        margin-bottom: 10px;
        line-height: 30px;
        .info-label {
            width: 72px;
            margin-right: 20px;
            text-align: left;
            color: #666;
        }
        .info-value {
            color: rgba(34,34,34, 0.6);
            .edit-btn {
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                color: #7486d9;
                padding-left: 16px;
                cursor: pointer;
            }
        }
    }
}
</style>