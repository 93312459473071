<template>
<div class="set-access">
    <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
        <OptButton size="small" type="primary"  class="_create" @click="showCreate">授权</OptButton>
        <OptButton size="small" icon="icon iconfont iconbianzu1711"
                   style="margin-left:8px;color:#949494;transform: translateY(2px);min-width: 32px;width: 32px!important;padding: 6px 7px!important;"
                   @click="getAccessList()"></OptButton>
    </div>
    <el-table :data="tableData" v-loading="loadingData" style="width: 100%">
<!--        <el-table-column type="selection" width="60"></el-table-column>-->
        <el-table-column prop="bucket" label="可访问存储桶" ></el-table-column>
        <el-table-column prop="regionName" label="所在资源节点" ></el-table-column>
        <el-table-column prop="auth" label="读写权限" >
            <template slot-scope="{$index, row}">
                {{ {READ: '读取权限', READ_AND_WRITE: '读写完全控制'}[row.auth] }}
            </template>
        </el-table-column>
        <el-table-column prop="description" label="描述" ></el-table-column>
        <el-table-column prop="createdAt" label="创建时间" >
            <template slot-scope="{$index, row}">
                {{ moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
            </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" >
            <template slot-scope="{$index, row}">
                {{ ['','启用','停用'][row.status] }}
            </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
            <template slot-scope="{ $index, row }">
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <span class="table-action-button" @click="handleEdit(row)">编辑</span>
                    <span  v-if="row.status === '1'" class="table-action-button" @click="handleUpdateStatus(row, '2')">停用</span>
                    <span  v-else class="table-action-button" @click="handleUpdateStatus(row, '1')">启用</span>
                    <span class="table-action-button" @click="handleDelete(row)">删除</span>
                </div>
            </template>
        </el-table-column>
    </el-table>
    <Pager class="bs-pager" v-model="pager" :sizes="[5, 10, 20]"
           @currentChange="handleCurrentChange" @sizeChange="handleSizeChange"></Pager>
    <el-dialog :title="title" :visible.sync="visible" append-to-body
               :close-on-click-modal="false" width="800px">
        <el-form :model="form" ref="form" @submit.native.prevent
                 :rules="rules"
                 label-width="100px" style="">
            <el-form-item label="子用户">
                {{ username }}
            </el-form-item>
            <el-form-item label="资源节点" prop="regionId">
                <el-select
                    v-model="form.regionId"
                    placeholder="选择资源池"
                    style="margin-right: 10px;"
                    @change="handleRegionChange"
                    :disabled="true"
                >
                    <el-option
                        v-for="item in regionList"
                        :value="item.regionId"
                        :label="item.regionName"
                        :key="item.regionId"/>
                </el-select>
            </el-form-item>
            <el-form-item label="选择桶" prop="bucket">
                <el-select
                    v-model="form.bucket"
                    placeholder="选择桶"
                    style="margin-right: 10px;"
                    :disabled="title === '编辑权限'"
                >
                    <el-option
                        v-for="item in bucketList"
                        :value="item.name"
                        :label="item.name"
                        :key="item.name"/>
                </el-select>
            </el-form-item>
            <el-form-item label="设置权限" prop="auth">
                <el-radio-group v-model="form.auth">
                    <el-radio label="READ">仅读取权限</el-radio>
                    <el-radio label="READ_AND_WRITE">读写完全控制</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="描述" prop="description">
                <el-input :maxlength="200" v-model.trim="form.description" placeholder="请输入描述 200字以内"/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCancel" >取消</el-button>
                <el-button size="small" type="primary" :loading="loading" @click="handleSubmit">确定</el-button>
            </span>
    </el-dialog>
</div>
</template>

<script>
import OptButton from '../../../components/OptButton'
import moment from 'moment'
import storage from "@/utils/storage";
export default {
    name: "set-access",
    components: {OptButton},
    props: ['accountId', 'username'],
    data () {
        return {
            tableData: [],
            loadingData: false,
            pager: {
                current: 1,
                size: 10,
                total: 0,
            },
            title: '添加授权',
            visible: false,
            form: {
                regionId: '',
                bucket: '',
                auth: '',
                description: ''
            },
            loading: false,
            regionList: [],
            bucketList: [],
            editBid: '',
            rules: {
                regionId: [{required: true, message: '请选择资源池'}],
                bucket: [{required: true, message: '请选择桶'}],
                auth: [{required: true, message: '请设置权限'}]
            },
            statusLoading: false,
            editStatus: ''
        }
    },
    mounted() {
        this.getAccessList()
        this.getRegionList()

    },
    methods: {
        moment,
        getRegionList () {
            this.$post('/region/listPurchased', {})
                .then(res => {
                    if (res.code === '200') {
                        this.regionList = [].concat(res.data || []);
                    }
                })
        },
        getAccessList () {
            const {accountId, pager} = this;
            this.loadingData = true
            this.$postJson('/auth/user/bucket/list', {
                accountId,
                page: {current: pager.current, size: pager.size}
            })
            .then(res => {
                if (res.code === '200') {
                    this.tableData = res.data.records
                    this.pager.total = res.data.total
                }
            })
            .finally(() => {
                this.loadingData = false
            })
        },
        showCreate () {
            this.visible = true
            this.title = '添加授权'
            this.form.regionId = storage.get('REGION_ID')
            this.form.bucket = ''
            this.form.description = ''
            this.form.auth = ''
            setTimeout(() => { this.$refs.form.clearValidate() }, 0)
            this.handleRegionChange(this.form.regionId)
        },
        handleCurrentChange (cur) {
            this.pager.current = cur;
            this.getAccessList()
        },
        handleSizeChange(size) {
            this.pager.current = 1;
            this.pager.size = size;
            this.getAccessList()
        },
        handleEdit ({regionId, bucket, auth, description, bid, status}) {
            this.title = '编辑权限'
            this.form.regionId = regionId
            this.form.bucket = bucket
            this.form.auth = auth
            this.form.description = description
            this.editBid = bid
            this.visible = true
            this.editStatus = status
        },
        handleUpdateStatus ({bid, regionId, bucket, auth, description}, status) {
            let data = {bid, status, regionId, bucket, auth, description, accountId: this.accountId}
            this.$confirm('确定' + (status === '1' ? '启用' : '停用') + '吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
            .then(() => {
                this.loadingData = true
                this.$postJson('/auth/user/bucket/update', data)
                .then(res => {
                    this.$message.success('操作成功')
                    this.getAccessList()
                })
                .catch(err => {
                    this.loadingData = false
                })
            })
        },
        handleDelete ({bid, bucket}) {
            this.$confirm(`<div><b>确认删除该权限？</b><br/><span>删除后，该子用户将无法拥有该存储桶的访问权限。</span></div>`, {
                title: '提示',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true
            })
            .then(() => {
                this.$postJson('/auth/user/bucket/delete', {userBucketAuthBid: bid})
                    .then(res => {
                        if (res.code === '200') {
                            this.$message.success('操作成功')
                            this.getAccessList()
                            this.$emit('reload')
                        }
                    })
            })
        },
        handleCancel () {
            this.visible = false
            this.$refs.form.resetFields();
        },
        handleSubmit () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const {form, accountId, title, editBid, editStatus} = this;
                    let data = {...form, accountId}, url = '/auth/user/bucket/create'
                    if (title === '编辑权限') {
                        url = '/auth/user/bucket/update'
                        data.bid = editBid
                        data.status = editStatus
                    }
                    this.loading = true
                    this.$postJson(url, data)
                        .then(res => {
                            if (res.code === '200') {
                                this.$message.success('操作成功')
                                this.visible = false
                                this.$refs.form.resetFields();
                                this.getAccessList()
                                this.$emit('reload')
                            }
                        })
                        .finally(() =>{
                            this.loading = false
                        })
                }
            })
        },
        handleRegionChange (resionId) {
            this.form.bucket = ''
            this.$postJson('/bucket/list?regionId=' + resionId)
            .then(res => {
                if (res.code === '200') {
                    this.bucketList = res.data
                }
            })
        }
    }
}
</script>

<style scoped>
.set-access {
}

</style>